import React from 'react';
// Import the image at the top if it's local
import devProfileImage from './dev profile.png';
import { Helmet } from 'react-helmet';
 
<Helmet>
<meta name='description' content=' Electrician near Pondicherry Plumber in Tamil Nadu Carpenter near Pondicherry Mason in Tamil Nadu Skilled laborer Pondicherry Certified plumber Tamil Nadu Experienced gardener Pondicherry Welding services Tamil Nadu Housekeeping services Pondicherry Home repair Pondicherry Construction workers Tamil Nadu Painting services near Pondicherry Local workers Pondicherry Service providers Tamil Nadu Part-time jobs near Pondicherry Daily wage labor Tamil Nadu Freelance electrician Pondicherry Contract labor Tamil Nadu Temporary worker Pondicherry Freelance plumber Tamil Nadu' />
</Helmet>
export default function ProfileCard({ name, description }) {
  return (
    <div className="bg-white shadow-lg rounded-lg p-4 flex items-center gap-4">
      <img
        src={devProfileImage} // Use the imported image
        alt={`${name}'s profile`} // More descriptive alt text
        className="w-16 h-16 rounded-full"
      />
      <div className="flex-1">
        <h2 className="font-bold text-gray-700">{name}Arishkumar.k</h2> {/* Only display the name prop */}
        <p className="text-gray-500">{description}🚀 Passionate full website developer crafting stunning sites! Let's build something amazing together!</p>
      </div>
      <a 
        href={`tel:${9655321915}`} 
        className="bg-green-500 text-white hover:bg-green-600 text-center py-2 px-2 rounded mt-1 transition-colors w-20 h-10"
      >
        Call
      </a>
    </div>
  );
}

