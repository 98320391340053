import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
// import SearchAndFilters from './components/SearchAndFilters';
import ProfileCard from './components/ProfileCard';
import Register from './components/registor'; 
import SearchAndFilters from './components/SearchAndFilters';// Create this component
// import GroceryList from './components/GroceryList'; // Create this component
import { Helmet } from "react-helmet";


function App() {
  // const profiles = [
  //   { name: 'Natarajan', description: 'Online class' },
  //   { name: 'S. Ravi subramanian', description: 'Welder' },
  // ];

  return (
    <Router>
            <Helmet>
        <title>JobSparke</title>
        <meta name='description' content='Find jobs and service providers in your community.' />
      </Helmet>
      <div className="bg-gray-100 min-h-screen">
        <Header />
          <div className="mt-4 grid gap-4">
          <Routes>
  <Route path="/" element={
    <>
      {/* <SearchAndFilters /> */}
        <SearchAndFilters />
        {/* <div className="mt-4 grid gap-4">
          {profiles.map((profile, index) => (
            <ProfileCard key={index} {...profile} />
          ))}
        </div> */}
        <ProfileCard />
    
    </>
  } />
  
  <Route path="/register" element={<Register />} />
  {/* <Route path="/grocery-list" element={<GroceryList />} /> */}
</Routes>
          </div>
        </div>
      
    </Router>
  );
}

export default App;

